.table-row-buttons {
    width: 100%;
    text-align: right;

    // Give some space between buttons.
    .btn {
        margin-right: 2px;
    }

    .btn-group {
        margin-right: 2px;
        .btn {
            margin-right: 0px;
        }
    }
}
