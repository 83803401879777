@import "../../../../../styles/_colors.scss";

.question-timer {
    .question-timer-seconds {
        display: inline-block;
        background-color: $gray-500;
        color: $white;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    &.question-timer-danger {
        .question-timer-seconds {
            background-color: $danger;
        }
    }
}
