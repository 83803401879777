.driver-score-progress-bar-as-chart {
    .driver-score-progress-bar-as-chart-main-progress-bar {
        .score-progress-bar {
            margin-top: 10px;
            max-width: 300px;
            max-height: 300px;

            .score-progress-bar-heading {
                font-size: 1.8rem;
            }

            .score-progress-bar-risk-name {
                font-size: 1.65rem;
            }

            .score-progress-bar-value {
                font-size: 4.8rem;
            }
        }
    }
}
