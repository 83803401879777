@import "../../../../../styles/_colors.scss";

.show-question-multiple-choice-image {
    // Overlay that hosts the question text and buttons.
    .show-question-multiple-choice-image-answer-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 10px;
        // Each answer button
        .col-12 {
            padding-top: 5px;
            padding-bottom: 5px;

            .show-question-multiple-choice-image-answer-button {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
}
