@import "../../../styles/_breakpoints.scss";

.navbar-brand {
    width: 100%;
    height: 100%;
    background-image: url('./Logo-less-padding.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-clip: padding-box;
    // Adjust the height to fit in the particular logo we are using.
    max-height: 51px;
    // Adjust the left padding for the particular logo we are using.
    margin-left: 8px;
    margin-right: 8px !important;
    // Use this instead of the above if you want a seperate logo when the sidebar is collapsed.
    // NOTE you'll also want to uncomment the two inner divs in AppNavbarBrand.tsx.
    /*.navbar-brand-full {
        width: 100%;
        height: 100%;
        background-image: url($publicRoot + '/img/logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
    }

    .navbar-brand-minimized {
        width: 100%;
        height: 100%;
        background-image: url($publicRoot + '/img/logo-minimized.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
    }*/
}

.brand-minimized {
    .navbar-brand {
        width: 155px !important;
    }
}


// Undo the logic by the coreui theme.
@include media-breakpoint-down (md) {
    .app-header {
        .navbar-brand {
            position: static;
            top: auto;
            left: auto;
            margin-left: 8px;

        }
    }
}

@include media-breakpoint-down (sm) {
    .app-header {
        .navbar-brand {
            margin-left: 0px;
        }
    }
}