@import "../../../../styles/_colors.scss";
//@import "../../../../../node_modules/rc-slider/assets/index.css";

// We've moved all the colors used by rc-slider into variables here to make them easy to update.

$rail-color: $gray-200;
$track-color: $rail-color; // Default was: #abe2fb but we want the track to be the same as the rail-color so we're not selecting "up to" a mark but just selecting a mark.
$handle-color: $primary;
$handle-border-color: darken($primary, 7.5%);
$handle-dragging-border-color: darken($handle-border-color, 4%);
$handle-dragging-shadow-color: $handle-border-color;
$handle-focused-border-color: $handle-border-color;
$handle-hover-border-color: $handle-dragging-border-color;
$handle-active-border-color: $handle-dragging-border-color;
$handle-active-shadow-color: $handle-dragging-border-color;
$mark-text-color: $body-color;
$mark-text-active-color: $mark-text-color; // Default was: #666 but we want enabled and disabled mark text to be styled the same.
$dot-border-color: $rail-color;
$dot-color: $white;
$dot-active-border-color: $dot-border-color; // We want this to be the same regardless of if a dot is before or after the selected mark.
$disabled-color: $rail-color;
$track-disabled-color: $gray-600;
$dot-disabled-border-color: $track-disabled-color;
$dot-disabled-color: $handle-color;
$tooltip-color: $gray-300;
$tooltip-text-color: $white;
$tooltip-shadow-color: $gray-600;

.sliding-scale-slider {
    // Style for the component itself
    min-height: 150px;
    padding-left: 50px;
    padding-right: 50px;

    // Slider apperance.  This has started as a copy of rc-slider/assets/index.css and has been mofieid for our needs, in particular
    // with the colours.
    .rc-slider {
        position: relative;
        height: 14px;
        padding: 5px 0;
        width: 100%;
        border-radius: 6px;
        touch-action: none;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-rail {
        position: absolute;
        width: 100%;
        background-color: $rail-color;
        height: 4px;
        border-radius: 6px;
    }

    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 4px;
        border-radius: 6px;
        background-color: $track-color;
    }

    .rc-slider-handle {
        position: absolute;
        width: 14px;
        height: 14px;
        cursor: pointer;
        cursor: -webkit-grab;
        margin-top: -5px;
        cursor: grab;
        border-radius: 50%;
        border: solid 2px $handle-border-color;
        background-color: $handle-color;
        touch-action: pan-x;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: $handle-dragging-border-color;
        box-shadow: 0 0 0 5px $handle-dragging-shadow-color;
    }

    .rc-slider-handle:focus {
        outline: none;
    }

    .rc-slider-handle-click-focused:focus {
        border-color: $handle-focused-border-color;
        box-shadow: unset;
    }

    .rc-slider-handle:hover {
        border-color: $handle-hover-border-color;
    }

    .rc-slider-handle:active {
        border-color: $handle-active-border-color;
        box-shadow: 0 0 5px $handle-active-shadow-color;
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }

    .rc-slider-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }

    .rc-slider-mark-text {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        color: $mark-text-color;
    }

    .rc-slider-mark-text-active {
        color: $mark-text-active-color;
    }

    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 4px;
        background: transparent;
    }

    .rc-slider-dot {
        position: absolute;
        bottom: -2px;
        margin-left: -4px;
        width: 8px;
        height: 8px;
        border: 2px solid $dot-border-color;
        background-color: $dot-color;
        cursor: pointer;
        border-radius: 50%;
        vertical-align: middle;
    }

    .rc-slider-dot-active {
        border-color: $dot-active-border-color;
    }

    .rc-slider-dot-reverse {
        margin-right: -4px;
    }

    .rc-slider-disabled {
        background-color: $disabled-color;
    }

    .rc-slider-disabled .rc-slider-track {
        background-color: $track-disabled-color;
    }

    .rc-slider-disabled .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
        border-color: $dot-disabled-border-color;
        box-shadow: none;
        background-color: $dot-disabled-color;
        cursor: not-allowed;
    }

    .rc-slider-disabled .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
        cursor: not-allowed !important;
    }

    .rc-slider-vertical {
        width: 14px;
        height: 100%;
        padding: 0 5px;
    }

    .rc-slider-vertical .rc-slider-rail {
        height: 100%;
        width: 4px;
    }

    .rc-slider-vertical .rc-slider-track {
        left: 5px;
        bottom: 0;
        width: 4px;
    }

    .rc-slider-vertical .rc-slider-handle {
        margin-left: -5px;
        touch-action: pan-y;
    }

    .rc-slider-vertical .rc-slider-mark {
        top: 0;
        left: 18px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-step {
        height: 100%;
        width: 4px;
    }

    .rc-slider-vertical .rc-slider-dot {
        left: 2px;
        margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:first-child {
        margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:last-child {
        margin-bottom: -4px;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        animation-duration: 0.3s;
        animation-fill-mode: both;
        display: block !important;
        animation-play-state: paused;
    }

    .rc-slider-tooltip-zoom-down-leave {
        animation-duration: 0.3s;
        animation-fill-mode: both;
        display: block !important;
        animation-play-state: paused;
    }

    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
        animation-name: rcSliderTooltipZoomDownIn;
        animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
        animation-name: rcSliderTooltipZoomDownOut;
        animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        transform: scale(0, 0);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .rc-slider-tooltip-zoom-down-leave {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    @keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            transform-origin: 50% 100%;
            transform: scale(0, 0);
        }

        100% {
            transform-origin: 50% 100%;
            transform: scale(1, 1);
        }
    }

    @keyframes rcSliderTooltipZoomDownOut {
        0% {
            transform-origin: 50% 100%;
            transform: scale(1, 1);
        }

        100% {
            opacity: 0;
            transform-origin: 50% 100%;
            transform: scale(0, 0);
        }
    }

    .rc-slider-tooltip {
        position: absolute;
        left: -9999px;
        top: -9999px;
        visibility: visible;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-tooltip * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-tooltip-hidden {
        display: none;
    }

    .rc-slider-tooltip-placement-top {
        padding: 4px 0 8px 0;
    }

    .rc-slider-tooltip-inner {
        padding: 6px 2px;
        min-width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 1;
        color: $tooltip-text-color;
        text-align: center;
        text-decoration: none;
        background-color: $tooltip-color;
        border-radius: 6px;
        box-shadow: 0 0 4px $tooltip-shadow-color;
    }

    .rc-slider-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: $tooltip-color;
    }
}
