@import "../../../styles/_colors.scss";

.question-tag-selector {
    min-height: calc(1.5em + 0.75rem + 2px);

    // Override height from .form-control
    &.form-control {
        height: auto;
    }

    .question-tag-button {
        margin-right: 3px;
        margin-bottom: 3px;
    }

    .question-tag-selector-input {
        width: 100%;
    }

    .question-tag-selector-dropdown {
        margin-top: 0.4rem;
        border-top: 1px solid $gray-400;
        padding-top: 0.6rem;
    }
}
