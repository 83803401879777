@import "../../../../styles/_colors.scss";

.page-progress {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .progress {
        background-color: $gray-400;
    }

    .page-progress-text {
        padding-left: 5px;
        font-weight: bold;
    }
}
