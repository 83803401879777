@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.dashboard-assessment-card {
    display: flex;
    flex-direction: column;

    &.dashboard-assessment-card-inline {
        // Inline styles only kick in on size md and above.
        @include media-breakpoint-up(md) {
            flex-direction: row;

            .dashboard-assessment-card-title {
                flex-grow: 1;
            }
        
            .dashboard-assessment-card-description {
                margin-left: 8px;
                margin-right: 8px;
            }

            .dashboard-assessment-card-description {
                margin-left: 8px;
                margin-right: 8px;
            }

            .dashboard-assessment-card-actions {
                border-top-style: none;
                margin-top: 0px;
                padding-top: 0px;
            }
        }
    }

    .dashboard-assessment-card-description {
        max-height: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 2;
    }

    .dashboard-assessment-card-actions {
        margin-top: 4px;
        border-top: 1px solid $gray-300;
        padding-top: 8px;
    }
}
