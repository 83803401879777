@import "../../../../../node_modules/react-circular-progressbar/dist/styles.css";
@import "../../../../styles/_colors.scss";

.score-progress-bar {
    max-height: 80px;
    max-width: 80px;

    .score-progress-bar-heading {
        font-size: 0.6rem;
        margin-top: -4px;
    }

    .score-progress-bar-risk-name {
        font-size: 0.55rem;
    }

    .score-progress-bar-value {
        font-size: 1.2rem;
    }

    &.score-progress-bar-Assessment {
        .CircularProgressbar-path {
            stroke: $success;
        }

        &.score-progress-bar-Assessment-High {
            .CircularProgressbar-path {
                stroke: $danger;
            }

            .score-progress-bar-value {
                color: $danger;
            }

            .score-progress-bar-risk-name {
                color: $danger;
            }
        }

        &.score-progress-bar-Assessment-Medium {
            .CircularProgressbar-path {
                stroke: $warning;
            }

            .score-progress-bar-value {
                color: $warning;
            }

            .score-progress-bar-risk-name {
                color: $warning;
            }
        }

        &.score-progress-bar-Assessment-Low {
            .CircularProgressbar-path {
                stroke: $success;
            }

            .score-progress-bar-value {
                color: $success;
            }

            .score-progress-bar-risk-name {
                color: $success;
            }
        }
    }

    &.score-progress-bar-TrainingModule {
        .CircularProgressbar-path {
            stroke: $esitu-darkblue;
        }
    }
    // Sizing
    &.score-progress-bar-sm {
        transform: scale(0.9);
    }

    &.score-progress-bar-md {
        // no scaling required.
    }

    &.score-progress-bar-lg {
        transform: scale(1.2);
    }
}
