@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

$background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(./traffic-332857_1920.jpg);
//$background-image: url(./background.jpg);

// Put the default background on the body (to avoid flicker switching around and allow it to be changed by supplying different data-background="" values.
body, body[data-background="default"] {
    background-image: $background-image;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //color: $dark-color;
    min-height: 100vh;
    background-attachment: fixed;
}

// Override the background image for screens that use a special background.
/*body[data-background="special1"] {
    background-image: url(put_special_image_here);
}*/
/*body[data-background="pink"] {
    background-image: unset;
    background-color: hotpink;
}*/

.background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    /*    background-color: $dark-bg;
    color: $dark-color;*/
    //background-image: $background-image;

    height: auto;
    min-height: 100%;
    /*    // If we are showing a Banner within a background, turn off the banner's background image.
    // If we want the banner background image to be different to the Background image we may want to turn this off.
    .banner {
        background-image: none;
        background-color: transparent;

        // We also want to get rid of excess height and padding at the bottom.
        height: auto;
        padding-bottom: 6px;
    }*/

    &.background-center-children-none {
        /* Do nothing */
    }

    &.background-center-children-vertically {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &.background-center-children-horizontally {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}