@import "../../../styles/_colors.scss";

.question-tag-button {
    font-size: 0.75rem;

    &.question-tag-button-gray {
        .btn {
            background-color: $gray-300;
            border-color: $gray-500;
        }
    }

    &.question-tag-button-readonly {
        .btn {
            cursor: default;

            &:hover {
                background-color: $secondary;
            }
        }
    }
}
