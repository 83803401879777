@import "../../styles/_colors.scss";

.main-container.privacy-policy {
    max-width: 1500px;
    font-size: .9rem;

    .text-container {
        padding: 50px;
    }

    h2 {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    h3 {
        font-size: .9rem;
        font-weight: 600;
    }
    // ordered lists with nesting
    ol {
        counter-reset: item;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    li {
        display: block;
        margin-left: -10px;
        list-style-position: inside;
        //text-indent: -43px;
        //padding-left: 43px;

        &.li-sub-heading {
            margin-top: 10px;

            span {
                font-weight: 600;
            }
        }

        p {
            margin-left: 30px;
        }

        table {
            width: 90%;
            margin-left: auto;
            margin-right: auto;

            th {
                width: 33%;
                padding: 5px;
            }

            td {
                padding: 5px;
            }

            tr {
                vertical-align: top;
            }

            &.with-borders {

                th {
                    border: 1px solid $black;
                }

                td {
                    border: 1px solid $black;
                }
            }

            &.without-borders {

                th {
                    border: 1px solid $white;
                }

                td {
                    border: 1px solid $white;
                }
            }
        }
    }

    li:before {
        content: counters(item, ".") " ";
        counter-increment: item;
        padding-right: 20px;
        font-weight: 600;
    }
} 