@import "../../../../../styles/_colors.scss";

.show-question-response-time-video {
    .show-question-response-time-video-background {
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 60vh;
        background-position-x: center;
        display: flex;
    }
    // Overlay for before the video starts.
    .show-question-response-time-video-start-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        text-align: center;

        .show-question-response-time-video-start-overlay-play-icon {
            font-size: 100px;
        }
    }
    // Overlay that hosts the question text and buttons.
    .show-question-response-time-video-answer-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 10px;
    }

    .show-question-response-time-video-click-button {
        width: 100%;
    }

    .show-question-response-time-video {
        border: 1px solid transparent;

        &.show-question-response-time-video-show-click-indicator {
            border-color: $primary;
        }

        // If we want the video to be full screen, lets handle that now.
        
            position: fixed;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            z-index: 100000;
            background-color: $black;

    }
}
