﻿// Configuration of variables that are passed to bootstrap and any theme.
//

@import "./_colors.scss";
@import "./_fonts.scss";

// Customise bootstrap variables.

// We want cards to be more rounded than normal.
$card-border-radius: 2rem;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1rem;

// We want buttons to be very round.
$btn-border-radius: 1rem;
$btn-padding-x: 1rem;
$btn-border-radius-sm: 1rem;
$btn-padding-x-sm: 1rem;

// We want rounded inputs.
$input-border-radius: 1rem;

// Customize coreui variables.
$sidebar-bg: $esitu-darkblue;
$sidebar-nav-link-icon-color: $gray-400;
$sidebar-nav-link-hover-bg: $esitu-pink;
$sidebar-nav-link-active-bg: $esitu-pink;
$sidebar-nav-link-active-icon-color: $white;

// Boostrap and coreui variables.
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/@coreui/coreui/scss/variables";
