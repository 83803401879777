@import "../../../styles/_colors.scss";

.interactive-image {
    margin-bottom: 0.2rem;
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;

    .interactive-image-loading-img {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}
