@import "../../../styles/_colors.scss";

.question-tag-display {
    .question-tag-button {
        margin-right: 3px;
        margin-bottom: 3px;

        > .btn {
            font-size: 0.8rem;
        }
    }
}
