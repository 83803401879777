.user-progress-dashboard-tab {
    .user-progress-dashboard-tab-item {
        .user-progress-dashboard-tab-item-right-side {
            display: flex;
            flex-direction: column;
            min-height: 100%;

            .user-progress-dashboard-tab-item-right-side-score {
                flex-grow: 1;
            }

            .user-progress-dashboard-tab-item-right-side-date {
                text-align: center;
            }

            .user-progress-dashboard-tab-item-right-side-attempts {
                text-align: center;
                color: gray;
                font-size: 10px;
                width: 190px;
            }
        }
    }
}
