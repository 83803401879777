.form-buttons {
    text-align: left;
    // Give some space between buttons.
    .btn {
        margin-right: 5px;
    }
    /*border-top: 1px solid lightgray;*/
    &:not(.form-buttons-no-padding) {
        margin-top: 2em;
        margin-bottom: 2em;
        padding-top: 4px;
    }
}

.form-cards { 

    margin-top: 10px;


}
