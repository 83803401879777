@import "../../../../../styles/_colors.scss";

.show-questionnaire-multiple-choice {

    .show-questionnaire-multiple-choice-video-answer-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 10px;


        .col-12 {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }


    .show-questionnaire-multiple-choice-answer-button {
        min-width: 100%;
        min-height: 100%;
    }
}