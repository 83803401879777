.sdk-overview {
    .sdk-overview-documentation-button {
        min-width: 100%;
        min-height: 100%;

        .sdk-overview-documentation-button-title {
            font-weight: bold;
            font-size: 1.05rem;
        }

        .sdk-overview-documentation-button-playground {
        }

        .sdk-overview-documentation-button-icon {
            font-size: 2.2rem;
        }
    }
}
