.launch-assessment-base {
    display: flex;
    flex-direction: column;

    .launch-assessment-base-main-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &.launch-assessment-base-main-container-full-screen {
            margin-top: 8px;
        }

        .launch-assessment-base-content {
            flex-grow: 1;
        }
    }
    // All videos/responsive embeds should be scaled to fit the height of the screen, not just the width.  Added a 80vh max height for them.
    .embed-responsive-16by9 {
        max-height: 80vh;
    }
}
