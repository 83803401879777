@import "../../../../../styles/_colors.scss";

.show-question-location-response-image {
    .show-question-location-response-image-background {
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 60vh;
        background-position-x: center;
        display: flex;
    }
    // Overlay for before the video starts.
    .show-question-location-response-image-start-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        text-align: center;

        .show-question-location-response-image-start-overlay-play-icon {
            font-size: 100px;
        }
    }
    // Overlay that hosts the question text and buttons.
    .show-question-location-response-image-answer-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 10px;
    }

    .show-question-location-response-image-click-button {
        width: 100%;
    }
}
