@import "../../../../../styles/_colors.scss";

.show-question-sliding-scale-video {
    .show-question-sliding-scale-video-background {
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 60vh;
        background-position-x: center;
        display: flex;
    }
    // Overlay for before the video starts.
    .show-question-sliding-scale-video-start-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        text-align: center;

        .show-question-sliding-scale-video-start-overlay-play-icon {
            font-size: 100px;
        }
    }
    // Overlay that hosts the question text and buttons.
    .show-question-sliding-scale-video-answer-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: rgba($white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 10px;

        .show-question-sliding-scale-video-answer-selected-answer-image-container {
        }
    }

    .show-question-sliding-scale-video-fullscreen {
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        z-index: 100000;
        background-color: $black;
    }

    .show-question-sliding-scale-video-fullscreen-toggle-fullscreened {
        z-Index: 100001;
        left: calc(50vw - (140.59px / 2));
        position: fixed;
        bottom: 10px;
    }

    .show-question-sliding-scale-video-fullscreen-toggle-normal {
        left: calc(50vw - (140.59px / 2));
        position: fixed;
    }
}
